import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { TuiRootModule } from "@taiga-ui/core";
import { VisuallyImpairedMenuComponent } from "@widgets";

@Component({
	selector: "app-root",
	standalone: true,
	imports: [RouterOutlet, TuiRootModule, VisuallyImpairedMenuComponent],
	templateUrl: "./app.component.html",
	styleUrl: "./app.component.less",
})
export class AppComponent {}
