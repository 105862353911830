import { AsyncPipe, NgIf } from "@angular/common";
import { Component, Inject, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LOADING_TOKEN } from "@shared";
import { TuiLoaderModule } from "@taiga-ui/core";
import { OlympiadGridCardsComponent } from "@widgets";
import {
	BehaviorSubject,
	combineLatestWith,
	debounceTime,
	distinctUntilChanged,
	first,
	map,
	Observable,
	Subject,
	switchMap,
	takeUntil,
} from "rxjs";
import { IOlympiadsContent, IResOlympiadsContent, PagesApiService } from "src/entity/pages";

@Component({
	standalone: true,
	selector: "app-olympiads-main",
	templateUrl: "./olympiads-main.template.html",
	styleUrl: "./olympiads-main.style.less",
	imports: [AsyncPipe, NgIf, OlympiadGridCardsComponent, TuiLoaderModule],
})
export class OlympiadsMainComponent implements OnDestroy {
	#destroy$: Subject<void> = new Subject<void>();

	isLoading$ = new BehaviorSubject<boolean>(false);
	needUpdate = true;

	#skip$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	#take$: BehaviorSubject<number> = new BehaviorSubject<number>(9);
	#menuNav$: BehaviorSubject<{ id: number; name: string } | null> = new BehaviorSubject<{
		id: number;
		name: string;
	} | null>(null);

	data$: BehaviorSubject<IOlympiadsContent[] | null> = new BehaviorSubject<IOlympiadsContent[] | null>(null);
	totalCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	constructor(
		private _route: ActivatedRoute,
		private _pagesApiService: PagesApiService,
		@Inject(LOADING_TOKEN) protected loading$: Observable<boolean>
	) {
		this._route.data.pipe(first()).subscribe((data) => {
			const menuId: number | null = data["menuId"];
			const routeTitle: string | null = data["name"];

			if (menuId && routeTitle) {
				this.#menuNav$.next({ id: menuId, name: routeTitle });
			}
		});
	}

	ngOnDestroy(): void {
		this.#destroy$.next();
		this.#destroy$.complete();
		this.#skip$.complete();
		this.#take$.complete();
		this.data$.complete();
		this.isLoading$.complete();
		this.#menuNav$.complete();
	}

	get buttonShow(): boolean {
		return this.#skip$.value + this.#take$.value < this.totalCount$.value;
	}

	protected addItems(): void {
		this.#skip$.next(this.#skip$.value + this.#take$.value);
	}

	#requestGetEducation$: Observable<IResOlympiadsContent> = this.#skip$.pipe(
		debounceTime(300),
		combineLatestWith(this.#take$),
		distinctUntilChanged(),
		takeUntil(this.#destroy$),
		switchMap(([skip, take]) => {
			const menuId = this.#menuNav$.value?.id || 0;
			return this.#getData({ menuItemId: menuId, take, skip });
		})
	);

	protected readonly getData$: Observable<boolean> = this.#requestGetEducation$.pipe(
		takeUntil(this.#destroy$),
		map((res) => {
			this.isLoading$.next(false);
			this.needUpdate = true;
			this.data$.next(this.data$.value ? [...this.data$.value, ...(res.rows as IOlympiadsContent[])] : res.rows);
			this.totalCount$.next(res.infoPage.totalCount || 0);
			return true;
		})
	);

	#getData(object: { menuItemId: number; take: number; skip: number }): Observable<IResOlympiadsContent> {
		return this._pagesApiService.getOlympiadsContent(object);
	}
}
