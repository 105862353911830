import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { InnerLayoutComponent } from "@widgets";
import { map, materialize, Observable, ObservableNotification, of, switchMap } from "rxjs";
import { EducationMainComponent } from "src/pages/education/main/education-main.component";
import { OlympiadsMainComponent } from "src/pages/olympiads/main/olympiads-main.component";
import { ProjectsComponent } from "src/pages/projects/projects.component";
import { breadcrumbsInner } from "../breadcrumbs";
import { EducationService } from "./education/education.service";
import { PagesApiService } from "./pages.api.service";
import { IResNewsDetails } from "./pages.interface";
import { eventByIdContentResolver, informationPageContentResolver } from "./pages.resolve";

export const informationPageGuard = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
	id: string
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	const localRoute = route.routeConfig;
	const pagesApiService = inject(PagesApiService);
	const router = inject(Router);

	if (localRoute) {
		if (localRoute.children && localRoute.children.length > 1) return true;
	}
	return pagesApiService.getDetails(id).pipe(
		switchMap((res) =>
			of(res).pipe(
				map((headers) => {
					if (localRoute) {
						localRoute.children = [
							...headers.contentItems.map((header) => ({
								path: String(header.src),
								title: header.name,
								component: InnerLayoutComponent,
								canActivate: [
									() =>
										breadcrumbsInner(
											{ id: header.id, name: header.name, src: String(header.src) },
											1,
											1
										),
								],
								resolve: {
									content: (route: ActivatedRouteSnapshot) =>
										informationPageContentResolver(route, String(header.src)),
								},
							})),
							{
								path: "feedback",
								title: "Обратная связь",
								canActivate: [
									() => breadcrumbsInner({ id: 0, name: "Обратная связь", src: "feedback" }, 1, 1),
								],
								loadComponent: () =>
									import("../../pages/feedback/feedback-page.component").then(
										(c) => c.FeedbackPageComponent
									),
							},
							{
								path: "**",
								redirectTo: headers.contentItems.length > 0 ? String(headers.contentItems[0].src) : "",
							},
						];
					}
					return true;
				})
			)
		)
	);
};

export const redirectGuard = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	const router = inject(Router);
	const routeUrl = [
		...(route.parent ? route.parent.url.map((item) => item.path) : ["**"]),
		...route.url.map((item) => item.path),
	];
	router.navigate(
		routeUrl.length === 1
			? [
					route?.parent?.url[0].path,
					route.parent?.routeConfig?.children ? route.parent.routeConfig.children[0].path : "",
				]
			: routeUrl
	);
	return true;
};

export const isExistNewsGuard = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	const router = inject(Router);
	const pagesApiService = inject(PagesApiService);
	const id = route.params["id"];

	return pagesApiService.getNewById(id).pipe(
		materialize(),
		map((res: ObservableNotification<IResNewsDetails>) => {
			if (res.kind === "E" || (res.kind === "N" && !res.value)) {
				router.navigate(["events"]);
				return false;
			} else {
				return true;
			}
		})
	);
};

// Page = Проекты
export const projectPageGuard = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
	id: string
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	const localRoute = route.routeConfig;
	const pagesApiService = inject(PagesApiService);
	const router = inject(Router);

	if (localRoute) {
		if (localRoute.children && localRoute.children.length > 1) return true;
	}
	return pagesApiService.getDetails(id).pipe(
		switchMap((res) =>
			of(res).pipe(
				map((headers) => {
					if (localRoute) {
						localRoute.children = [
							...headers.contentItems.map((header) => ({
								path: String(header.src),
								title: header.name,
								component: ProjectsComponent,
								canActivate: [
									() =>
										breadcrumbsInner(
											{ id: header.id, name: header.name, src: String(header.src) },
											1,
											1
										),
								],
								resolve: {
									content: (route: ActivatedRouteSnapshot) =>
										informationPageContentResolver(route, String(header.src)),
								},
							})),
							{
								path: "**",
								redirectTo: headers.contentItems.length > 0 ? String(headers.contentItems[0].src) : "",
							},
						];
					}
					return true;
				})
			)
		)
	);
};

// Page = Обучение
export const educationPageGuard = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	const localRoute = route.routeConfig;
	const pagesApiService = inject(PagesApiService);
	const router = inject(Router);
	const educationService = inject(EducationService);

	if (localRoute) {
		if (localRoute.children && localRoute.children.length > 1) return true;
	}
	return pagesApiService.getEducationMeta().pipe(
		switchMap((res) =>
			of(res).pipe(
				map((meta) => {
					if (localRoute) {
						localRoute.children = [
							...meta.menu.map((header) => ({
								path: String(header.src),
								title: header.name,
								children: [
									{
										path: "",
										data: { menuId: header.id, name: header.name, filters: meta.filters },
										canActivate: [
											() =>
												breadcrumbsInner(
													{ id: header.id, name: header.name, src: String(header.src) },
													0,
													1
												),
										],
										component: EducationMainComponent,
									},
									{
										path: ":id",
										resolve: { content: eventByIdContentResolver },
										loadComponent: () =>
											import("../../pages/education/details/education-details.component").then(
												(p) => p.EducationDetailsComponent
											),
									},
								],
							})),
							{
								path: "**",
								redirectTo: meta.menu.length > 0 ? String(meta.menu[0].src) : "",
							},
						] as Route[];
					}
					return true;
				})
			)
		)
	);
};

// Page - Олимпиады
export const olympiadsPageGuard = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	const localRoute = route.routeConfig;
	const pagesApiService = inject(PagesApiService);

	if (localRoute) {
		if (localRoute.children && localRoute.children.length > 1) return true;
	}
	return pagesApiService.getOlympiadsMeta().pipe(
		switchMap((res) =>
			of(res).pipe(
				map((meta) => {
					if (localRoute) {
						localRoute.children = [
							...meta.map((header) => ({
								path: String(header.src),
								title: header.name,
								children: [
									{
										path: "",
										canActivate: [
											() =>
												breadcrumbsInner(
													{ id: header.id, name: header.name, src: String(header.src) },
													0,
													1
												),
										],
										data: { menuId: header.id, name: header.name },
										component: OlympiadsMainComponent,
									},
									{
										path: ":id",
										resolve: { content: eventByIdContentResolver },
										loadComponent: () =>
											import("../../pages/olympiads/details/olympiads-details.component").then(
												(p) => p.OlympiadsDetailsComponent
											),
									},
								],
							})),
							{
								path: "**",
								redirectTo: meta.length > 0 ? String(meta[0].src) : "",
							},
						] as Route[];
					}
					return true;
				})
			)
		)
	);
};

export const simplePageGuard = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
	id: string
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	const localRoute = route.routeConfig;
	const pagesApiService = inject(PagesApiService);
	const router = inject(Router);

	if (localRoute) {
		if (localRoute.children && localRoute.children.length > 1) return true;
	}
	return pagesApiService.getDetails(id).pipe(
		switchMap((res) =>
			of(res).pipe(
				map((headers) => {
					if (localRoute) {
						localRoute.children = [
							...headers.contentItems.map((header) => ({
								path: String(header.src),
								title: header.name,
								canActivate: [
									() =>
										breadcrumbsInner(
											{ id: header.id, name: header.name, src: String(header.src) },
											0,
											1
										),
								],
								component: InnerLayoutComponent,
								resolve: {
									content: (route: ActivatedRouteSnapshot) =>
										informationPageContentResolver(route, String(header.src)),
								},
							})),
							{
								path: "**",
								redirectTo: headers.contentItems.length > 0 ? String(headers.contentItems[0].src) : "",
							},
						];
					}
					return true;
				})
			)
		)
	);
};
