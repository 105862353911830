import { AsyncPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { Component, Inject, inject, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BREAKPOINTS, LOADING_TOKEN } from "@shared";
import { TuiButtonModule, TuiLoaderModule } from "@taiga-ui/core";
import { EducationsComponent } from "@widgets";
import {
	BehaviorSubject,
	combineLatestWith,
	debounceTime,
	distinctUntilChanged,
	first,
	map,
	Observable,
	Subject,
	switchMap,
	takeUntil,
} from "rxjs";
import { IEducationContent, IResEducationContent, PagesApiService } from "src/entity/pages";
import { EducationService } from "src/entity/pages/education/education.service";

@Component({
	standalone: true,
	selector: "app-education-main",
	templateUrl: "./education-main.template.html",
	styleUrl: "./education-main.style.less",
	imports: [NgIf, AsyncPipe, NgForOf, NgClass, TuiButtonModule, EducationsComponent, TuiLoaderModule],
})
export class EducationMainComponent implements OnDestroy {
	protected Object = Object;

	#destroy$: Subject<void> = new Subject<void>();
	#educationService = inject(EducationService);
	breakpoint$ = inject(BREAKPOINTS);

	isLoading$ = new BehaviorSubject<boolean>(false);
	needUpdate = true;

	filters$: BehaviorSubject<{ [key: string]: string } | null> = new BehaviorSubject<{
		[key: string]: string;
	} | null>(null);

	filter$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
	#skip$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	#take$: BehaviorSubject<number> = new BehaviorSubject<number>(9);
	#sortDate$: BehaviorSubject<"asc" | "desc"> = new BehaviorSubject<"asc" | "desc">("desc");
	data$: BehaviorSubject<IEducationContent[] | null> = new BehaviorSubject<IEducationContent[] | null>(null);
	totalCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	color$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

	constructor(
		private _route: ActivatedRoute,
		private _pagesApiService: PagesApiService,
		@Inject(LOADING_TOKEN) protected loading$: Observable<boolean>
	) {
		this._route.data.pipe(first()).subscribe((data) => {
			const meta: { [key: string]: string } | null = data["filters"];
			const menuId: number | null = data["menuId"];
			const routeTitle: string | null = data["name"];

			if (menuId && routeTitle) {
				this.#educationService.menuNav$.next({ id: menuId, name: routeTitle });
			}

			if (meta) {
				this.filters$.next({ ...meta });
			}
		});
	}

	ngOnDestroy(): void {
		this.#destroy$.next();
		this.#destroy$.complete();
		this.filters$.complete();
		this.filter$.complete();
		this.#skip$.complete();
		this.#take$.complete();
		this.#sortDate$.complete();
		this.data$.complete();
		this.isLoading$.complete();
		this.color$.complete();
	}

	protected addItems(): void {
		this.#skip$.next(this.#skip$.value + this.#take$.value);
	}

	get iconSort(): string {
		return this.#sortDate$.value === "asc" ? "tuiIconChevronDown" : "tuiIconChevronUp";
	}

	get buttonShow(): boolean {
		return this.#skip$.value + this.#take$.value < this.totalCount$.value;
	}

	protected changeSortMode(): void {
		this.needUpdate = false;
		this.#sortDate$.next(this.#sortDate$.value === "asc" ? "desc" : "asc");
	}

	protected changeFilter(filter: string): void {
		this.needUpdate = false;
		this.filter$.next(filter === this.filter$.value ? null : filter);
	}

	#requestGetEducation$: Observable<IResEducationContent> = this.filter$.pipe(
		debounceTime(300),
		combineLatestWith(this.#skip$, this.#take$, this.#sortDate$),
		distinctUntilChanged(),
		takeUntil(this.#destroy$),
		switchMap(([filter, skip, take, sortDate]) => {
			const formatFilter = filter ?? undefined;
			const menuId = this.#educationService.menuNav$.value?.id || 0;
			return this.#getData({ menuItemId: menuId, filter: formatFilter, skip, take, sortDate });
		})
	);

	protected readonly getData$: Observable<boolean> = this.#requestGetEducation$.pipe(
		takeUntil(this.#destroy$),
		map((res) => {
			this.isLoading$.next(false);
			this.data$.next(
				this.data$.value && this.needUpdate
					? [...this.data$.value, ...(res.rows as IEducationContent[])]
					: res.rows
			);
			this.totalCount$.next(res.infoPage.totalCount || 0);
			this.#setColor(this.#educationService.menuNav$.value?.id || 0);
			this.needUpdate = true;
			return true;
		})
	);

	#setColor(id: number) {
		switch (id) {
			case 112: {
				this.color$.next("#336E5C");
				break;
			}
			case 120: {
				this.color$.next("#FF8945");
				break;
			}
			case 121: {
				this.color$.next("#888888");
				break;
			}
			case 122: {
				this.color$.next("#9B2227");
				break;
			}
			default: {
				this.color$.next("#000000");
				break;
			}
		}
	}

	#getData(object: {
		menuItemId: number;
		filter?: string;
		skip: number;
		take: number;
		sortDate: "asc" | "desc";
	}): Observable<IResEducationContent> {
		return this._pagesApiService.getEducationContent(object);
	}
}
