import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { AIS_URL, API_URL } from "@app";
import { DIALOG_PROVIDER, ERROR_PROVIDER, LOADING_PROVIDER } from "@shared";
import { TuiSafeHtml } from "@taiga-ui/cdk";
import { TUI_SANITIZER, TuiDialogModule, TuiRootModule, tuiSvgSrcInterceptors } from "@taiga-ui/core";
import { tuiBreadcrumbsOptionsProvider } from "@taiga-ui/kit";
import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import { PolymorpheusModule } from "@tinkoff/ng-polymorpheus";
import { environment } from "environments/environment";
import { routes } from "./app.routes";

export const appConfig: ApplicationConfig = {
	providers: [
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimations(),
		provideZoneChangeDetection({ eventCoalescing: true }),
		// provideRouter(routes),
		{
			provide: API_URL,
			useValue: environment.api_url,
		},
		{
			provide: AIS_URL,
			useValue: environment.ais_url,
		},
		importProvidersFrom(
			RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
			TuiRootModule,
			TuiDialogModule,
			PolymorpheusModule
		),
		tuiSvgSrcInterceptors((src: TuiSafeHtml) => (String(src).startsWith("tui") ? src : `svg/${src}.svg`)),
		{ provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
		tuiBreadcrumbsOptionsProvider({
			icon: "tuiIconMinus",
		}),
		DIALOG_PROVIDER,
		LOADING_PROVIDER,
		ERROR_PROVIDER,
	],
};
