import { AsyncPipe, NgIf } from "@angular/common";
import { AfterViewInit, Component, ComponentRef, inject, OnDestroy, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BREAKPOINTS } from "@shared";
import {
	ContactsSpecialPageMainComponent,
	EventsSpecialPageMainComponent,
	FlexContainerComponent,
	GallerySpecialPageMainComponent,
	LinksPhotoSpecialPageMainComponent,
	PhotoSpecialPageMainComponent,
} from "@widgets";
import { BehaviorSubject, first, Subject, takeUntil } from "rxjs";
import { IHeaderContent } from "src/entity/pages";

@Component({
	standalone: true,
	selector: "app-main-pages",
	templateUrl: "./main-pages.template.html",
	styleUrl: "./main-pages.style.less",
	imports: [NgIf, AsyncPipe],
})
export class MainPagesComponent implements AfterViewInit, OnDestroy {
	@ViewChild("main", { read: ViewContainerRef }) dynamicContainer?: ViewContainerRef;
	containerRef?: ComponentRef<FlexContainerComponent>;
	galleryComponent?: ComponentRef<GallerySpecialPageMainComponent>;

	content$: BehaviorSubject<IHeaderContent | null> = new BehaviorSubject<IHeaderContent | null>(null);
	#destroy$: Subject<void> = new Subject<void>();
	firstRender$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	#route = inject(ActivatedRoute);
	#breakpoint$ = inject(BREAKPOINTS);
	isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor() {
		this.#breakpoint$.pipe(takeUntil(this.#destroy$)).subscribe((res) => {
			this.isMobile$.next(res === "mobile");
		});
		this.#route.data.pipe(first()).subscribe((data: any) => {
			this.content$.next(data.content);
		});
	}

	ngAfterViewInit(): void {
		this.#createDynamicComponent();
		this.#breakpoint$.pipe(takeUntil(this.#destroy$)).subscribe((res) => {
			this.#createDynamicComponentMobile(res === "mobile");
		});
	}

	#createDynamicComponentMobile(mobile: boolean): void {
		if (!this.dynamicContainer) return;
		if (this.firstRender$.value && this.galleryComponent) {
			this.galleryComponent.instance.options = this.content$.value?.content[mobile ? 1 : 0];
			this.galleryComponent.changeDetectorRef.detectChanges();
		}
	}

	#createDynamicComponent(): void {
		if (!this.dynamicContainer) return;

		if (this.content$.value) {
			const contentArray = this.content$.value.content;
			const isMobile = this.isMobile$.value;

			this.galleryComponent = this.dynamicContainer.createComponent(GallerySpecialPageMainComponent);
			this.galleryComponent.instance.options = contentArray[isMobile ? 1 : 0];
			this.galleryComponent.changeDetectorRef.detectChanges();

			const photoComponent = this.dynamicContainer.createComponent(PhotoSpecialPageMainComponent);
			photoComponent.instance.options = contentArray[2];
			photoComponent.changeDetectorRef.detectChanges();

			const eventsComponent = this.dynamicContainer.createComponent(EventsSpecialPageMainComponent);
			eventsComponent.instance.options = contentArray[6].data.slice(0, 6);
			eventsComponent.changeDetectorRef.detectChanges();

			const linksPhotoComponent = this.dynamicContainer.createComponent(LinksPhotoSpecialPageMainComponent);
			linksPhotoComponent.instance.options = contentArray[3];
			linksPhotoComponent.changeDetectorRef.detectChanges();

			if (!this.containerRef) {
				this.containerRef = this.dynamicContainer.createComponent(FlexContainerComponent);
				this.containerRef.changeDetectorRef.detectChanges();
			}
			const contactsComponent = this.containerRef.instance.container?.createComponent(
				ContactsSpecialPageMainComponent
			);
			if (contactsComponent) {
				contactsComponent.instance.options = contentArray[4];
				contactsComponent.changeDetectorRef.detectChanges();
			}
			const contactsComponent2 = this.containerRef.instance.container?.createComponent(
				ContactsSpecialPageMainComponent
			);
			if (contactsComponent2) {
				contactsComponent2.instance.options = contentArray[5];
				contactsComponent2.changeDetectorRef.detectChanges();
			}

			this.firstRender$.next(true);
		}
	}

	ngOnDestroy(): void {
		this.#destroy$.next();
		this.#destroy$.complete();
		this.isMobile$.complete();
		this.content$.complete();
		this.firstRender$.complete();
	}
}
