import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, combineLatestWith, map, shareReplay, Subject, takeUntil } from "rxjs";
import { IEducationContent, IHeaderDetail } from "../pages.interface";

@Injectable()
export class EducationService implements OnDestroy {
	#destroy$: Subject<void> = new Subject<void>();

	menuNav$: BehaviorSubject<IHeaderDetail | null> = new BehaviorSubject<IHeaderDetail | null>(null);
	contentData$: BehaviorSubject<IEducationContent | null> = new BehaviorSubject<IEducationContent | null>(null);

	globalObservable$ = this.menuNav$.pipe(
		combineLatestWith(this.contentData$),
		takeUntil(this.#destroy$),
		map(([menu, content]) => ({ menu, content })),
		shareReplay(1)
	);

	ngOnDestroy(): void {
		this.#destroy$.next();
		this.#destroy$.complete();
		this.menuNav$.complete();
		this.contentData$.complete();
	}
}
