import { ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from "@angular/router";
import { breadcrumbsHeaders } from "@entity";
import {
	educationPageDetailsResolve,
	eventDetailsPageContentResolver,
	eventsPageContentResolver,
	informationPageDetailsResolve,
	mainPageContentResolver,
	olympiadsPageDetailsResolve,
	otherPageDetailsResolve,
	pageDetailsByIdResolve,
	projectsPageDetailsResolve,
} from "src/entity/pages";
import { EducationService } from "src/entity/pages/education/education.service";
import {
	educationPageGuard,
	informationPageGuard,
	isExistNewsGuard,
	olympiadsPageGuard,
	projectPageGuard,
	redirectGuard,
	simplePageGuard,
} from "src/entity/pages/pages.guard";
import { MainPagesComponent } from "src/pages/main-pages/main-pages.component";

export const routes: Routes = [
	{
		path: "",
		loadComponent: () => import("../layout/main/main-layout.component").then((c) => c.MainLayoutComponent),
		children: [
			{
				path: "glavnaya_stranica",
				title: "Главная страница",
				resolve: { content: mainPageContentResolver },
				component: MainPagesComponent,
			},
			{
				path: "svedeniya_ob_oo",
				title: "Сведения об ОО",
				canActivate: [
					(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
						informationPageGuard(route, state, "svedeniya_ob_oo"),
					(route: ActivatedRouteSnapshot) =>
						breadcrumbsHeaders(route, { id: 2, name: "Сведения об ОО", src: "svedeniya_ob_oo" }),
				],
				resolve: {
					navs: (route: ActivatedRouteSnapshot) => informationPageDetailsResolve(route, "svedeniya_ob_oo"),
				},
				loadComponent: () => import("../layout/sidebar/sidebar.component").then((c) => c.SidebarComponent),
				children: [
					{
						path: "**",
						canActivate: [redirectGuard],
						loadComponent: () => import("../layout/empty/empty.component").then((c) => c.EmptyComponent),
					},
				],
			},
			{
				path: "sobytiya",
				title: "События",
				children: [
					{
						path: "",
						resolve: { meta: eventsPageContentResolver },
						loadComponent: () =>
							import("../pages/events-pages/events-pages.component").then((p) => p.EventsPagesComponent),
					},
					{
						path: ":id",
						canActivate: [isExistNewsGuard],
						resolve: { content: eventDetailsPageContentResolver },
						loadComponent: () =>
							import("../pages/event-details/event-details.component").then(
								(p) => p.EventDetailsComponent
							),
					},
				],
			},
			{
				path: "proekty",
				title: "Проекты",
				canActivate: [
					(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
						projectPageGuard(route, state, "proekty"),
					(route: ActivatedRouteSnapshot) =>
						breadcrumbsHeaders(route, { id: 6, name: "Проекты", src: "proekty" }),
				],
				resolve: { navs: projectsPageDetailsResolve },
				loadComponent: () => import("../layout/sidebar/sidebar.component").then((c) => c.SidebarComponent),
				children: [
					{
						path: "**",
						canActivate: [redirectGuard],
						loadComponent: () => import("../layout/empty/empty.component").then((c) => c.EmptyComponent),
					},
				],
			},
			{
				path: "obuchenie",
				title: "Обучение",
				canActivate: [
					educationPageGuard,
					(route: ActivatedRouteSnapshot) =>
						breadcrumbsHeaders(route, { id: 4, name: "Обучение", src: "obuchenie" }),
				],
				resolve: { navs: educationPageDetailsResolve },
				loadComponent: () => import("../layout/sidebar/sidebar.component").then((c) => c.SidebarComponent),
				providers: [EducationService],
				children: [
					{
						path: "**",
						canActivate: [redirectGuard],
						loadComponent: () => import("../layout/empty/empty.component").then((c) => c.EmptyComponent),
					},
				],
			},
			{
				path: "olimpiady_i_konkursy",
				title: "Олимпиады и конкурсы",
				canActivate: [
					olympiadsPageGuard,
					(route: ActivatedRouteSnapshot) =>
						breadcrumbsHeaders(route, { id: 5, name: "Олимпиады и конкурсы", src: "olimpiady_i_konkursy" }),
				],
				resolve: { navs: olympiadsPageDetailsResolve },
				loadComponent: () => import("../layout/sidebar/sidebar.component").then((c) => c.SidebarComponent),
				children: [
					{
						path: "**",
						canActivate: [redirectGuard],
						loadComponent: () => import("../layout/empty/empty.component").then((c) => c.EmptyComponent),
					},
				],
			},
			{
				path: "partnery",
				title: "Партнеры",
				canActivate: [
					(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
						simplePageGuard(route, state, "partnery"),
					(route: ActivatedRouteSnapshot) =>
						breadcrumbsHeaders(route, { id: 7, name: "Партнеры", src: "partnery" }),
				],
				resolve: { navs: (route: ActivatedRouteSnapshot) => pageDetailsByIdResolve(route, "partnery") },
				loadComponent: () => import("../layout/sidebar/sidebar.component").then((c) => c.SidebarComponent),
				children: [
					{
						path: "**",
						canActivate: [redirectGuard],
						loadComponent: () => import("../layout/empty/empty.component").then((c) => c.EmptyComponent),
					},
				],
			},
			{
				path: "prochee",
				title: "Прочее",
				children: [
					{
						path: "",
						canActivate: [
							(route: ActivatedRouteSnapshot) =>
								breadcrumbsHeaders(route, { id: 9, name: "Прочее", src: "other" }),
						],
						loadComponent: () =>
							import("../pages/other/main/other-main.component").then((p) => p.OtherMainComponent),
					},
					{
						path: ":id",
						resolve: { content: otherPageDetailsResolve },
						loadComponent: () =>
							import("../pages/other/details/other-details.component").then(
								(p) => p.OtherDetailsComponent
							),
					},
				],
			},
			{
				path: "**",
				redirectTo: "glavnaya_stranica",
				pathMatch: "full",
			},
		],
	},
];
