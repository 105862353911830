<div class="head">
    <div class="dropdown dropdown--sort">
        <button tuiButton [iconRight]="iconSort" appearance="button-sort" (click)="changeSortMode()" size="s"
            class="dropdown__btn">{{(breakpoint$ | async) === 'desktop' ? 'Сортировать по дате' : 'Дата' }} </button>
    </div>
    <div class="head-sort">
        <ng-container *ngIf="(filters$ | async) as filters">
            <button [ngClass]="{'chip__input': true, 'checked': (filter$ | async) === filter[0]}"
                *ngFor="let filter of Object.entries(filters)" (click)="changeFilter(filter[0])">
                <span class="chip__input--text">{{filter[1]}}</span>
            </button>
        </ng-container>
    </div>
</div>
<app-educations *ngIf="getData$ | async" [options]="data$ | async" [activeColor]="color$ | async"></app-educations>
<button class="btn btn--center" *ngIf="buttonShow && !(loading$ | async)" (click)="addItems()">Загрузить еще</button>
<tui-loader [showLoader]="!!(loading$ | async)" [overlay]="true"></tui-loader>